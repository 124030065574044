import React from "react"

import MobileVerification from "components/Auth/MobileVerification"

export default ({ location }) => {
  const pageContext = {
    nextPath: `/`,
    backPath: `/`,
    module: {
      name: `input-number`,
      cta: `Next`,
      title: `Input Mobile Number`,
      subtitle: `Please enter your mobile number to verify your transaction.`,
      seoTitle: `Input Mobile Number`,
    },
  }

  return <MobileVerification location={location} pageContext={pageContext} />
}
