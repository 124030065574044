import { useContext } from "react"

import { authFormFields } from "./utils/authFormFields"
import { generateFormField } from "elements/Form/services/form"
import { AppContext } from "context/AppContext"

const AuthForm = ({
  module,
  values,
  errors,
  submitCount,
  setFieldValue,
  handleResendOTP,
}) => {
  const { state, dispatch } = useContext(AppContext)
  let formFields = authFormFields(values, handleResendOTP)

  formFields = formFields.filter((formField) => {
    return formField.inclusions.includes(module)
  })

  formFields = formFields.map((formField) => {
    if (!formField?.referenceAnswer) {
      return generateFormField({
        formField,
        values,
        formFields,
        errors,
        submitCount,
        setFieldValue,
        state,
        dispatch,
        type: "auth",
      })
    }
    return null
  })

  return formFields
}

export default AuthForm
