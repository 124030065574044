import { navigate } from "gatsby"
import axios from "axios"
import firebase from "firebase"

import { parseAuthErrorMessage } from "./errors"
import { sendOTP } from "./signin"

export const handleAuthSubmit = async ({ payload, zendeskId }) => {
  let { setLoading, setMessage, otpVerifier, authDispatch } = payload
  let mobileNumber = payload?.values?.mobileNumber
  let otp = payload?.values?.otp
  mobileNumber = `+63${mobileNumber}`

  switch (payload.module) {
    case "input-number":
      setLoading(true)
      let tempOtpVerifier = await sendOTP({
        mobileNumber,
      })
      setLoading(false)
      authDispatch({
        type: "SAVE_DETAILS",
        payload: {
          otpVerifier: tempOtpVerifier,
          mobileNumber: payload?.values?.mobileNumber,
        },
      })
      payload.resetForm()
      navigate("/epharmacy/verify-last-name")
      break

    case "verify-last-name":
      const response = await axios.post(
        process.env.GATSBY_GET_ORDER_DETAILS_WEBHOOK,
        {
          zendeskId,
        }
      )
      const submittedLastName = payload?.values?.lastName
      const orderLastName = response?.data?.lastName

      if (submittedLastName === orderLastName) {
        sessionStorage.setItem("hasValidatedLastName", true)
        navigate(
          `/epharmacy/proof-of-payment${!!zendeskId && `?z=${zendeskId}`}`
        )
      } else {
        setMessage({
          type: "danger",
          content:
            "The Last Name you have inputted does not match with the Last Name you used to place your order. Please try again.",
        })
        setLoading(false)
      }
      break

    default:
      break
  }
}
