import * as Yup from "yup"

const REQUIRED_MESSAGE = "This field is required."

export const authValidationSchema = (module) => {
  let validationSchema = {}

  switch (module) {
    case "input-number":
      validationSchema.mobileNumber = Yup.string()
        .min(
          10,
          "Please input a valid mobile number in this format: 9991234567"
        )
        .max(
          10,
          "Please input a valid mobile number in this format: 9991234567"
        )
        .matches(
          /^9[0-9]{9}$/,
          "Please input a valid mobile number in this format: 9991234567"
        )
        .required(REQUIRED_MESSAGE)
      break
    case "verify-last-name":
      validationSchema.lastName = Yup.string()
      .required(REQUIRED_MESSAGE)
      break
  }
  return Yup.object().shape(validationSchema)
}
