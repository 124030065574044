import firebase from "firebase"

export const sendOTP = async ({ mobileNumber }) => {
  try {
    let recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
      }
    )
    let firebaseResponse = await firebase
      .auth()
      .signInWithPhoneNumber(mobileNumber, recaptchaVerifier)
    return firebaseResponse
  } catch (error) {}
}
